import React from 'react'
import { StepContainer, Step, Circle } from './_style'

interface IStepperProps {
  data: {title: string}[];
}

const Stepper = ({ data }: IStepperProps) => {
  return (
    <StepContainer>
      {data?.map((content: {title: string}, i: number) => (
        <Step key={i}>
          <div className='content'>
            <Circle>{i + 1}</Circle>
            <div className='text-grayscale--500'>{content.title}</div>
          </div>
        </Step>
      ))}
    </StepContainer>
  )
}

export default Stepper
