import React from 'react'
import FAQ from 'src/components/Faq/index'
import { FaqT } from '../_types'

const FAQComponent = ({ faq }: FaqT) => {
  return (
    <section>
      <div className='container pt-5 pb-4 py-lg-5'>
        <div className='row'>
          <div className='mb-4'>
            <h2 className='fw-700 fs-24 lh-30 fs-md-40 lh-md-50 text-grayscale--500 mb-md-3'>Dúvidas frequentes sobre o Pix</h2>
          </div>
          <FAQ answerData={faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} />
        </div>
      </div>
    </section>
  )
}

export default FAQComponent
