import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'
import { Card } from '@interco/inter-ui/components/Card'

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${orange.extra};
  border-radius: 50%;
  padding: 8px;
`

export const CardBody = styled.div`
  max-height: 280px;
  overflow-y: auto;
  &::-webkit-scrollbar {
      width: 4px;
      background-color: ${grayscale[200]};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${orange.extra};
      border-radius: 4px;
    }
`

export const ClientQuoteCards = styled(Card)`
    max-height: 425px;
    max-width: 242px;
    padding: 40px 27px;
`
