import usePageQuery from '../../pageQuery'
import { ModalPixDataT, useModalLpPixDataT } from '../../sections/_types'
import modalData from './modalData.json'

export const useModalLpPixData = (): useModalLpPixDataT => {
  const data = usePageQuery()

  return {
    getImageFluid: (id: string) => data[id].fluid,
    getLink: (id: string) => modalData.find((item: ModalPixDataT) => id === item.qrcode)?.link,
  }
}
