import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import { InterP } from 'src/components/Text/BodyText'
import { H2Citrina, H3Citrina } from 'src/components/Text/Headings'

import { primary } from 'src/styles/newColors'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import * as S from './_style'
import { grayscale } from 'src/styles/colors'

const TEXT_CONTENT = {
  heading: 'O que os nossos clientes dizem?',
}

const OQueClientesDizem = () => {
 if (true) return null

  return (
    <SectionComponent
      id='o-que-clientes-dizem'
      bgColor={primary[100]}
      minHeight={{ sm: '629px', md: '629px', lg: '629px', xl: '629px', xxl: '629px' }}
    >
      <H2Citrina className='mb-2'>{TEXT_CONTENT.heading}</H2Citrina>
      <S.ClientQuoteCards className='mt-2'>
        <header className='d-flex align-items-center mb-3'>
          <S.IconWrapper className='mr-3'>
            <OrangeIcon icon='user-account' color='#000' size='SM' />
          </S.IconWrapper>
          <H3Citrina fontSize={{ sm: '13px' }} lineHeight={{ sm: '16px' }}>Oliveira Henriques</H3Citrina>
        </header>
        <S.CardBody>
          <InterP fontSize={{ sm: '13px' }} lineHeight={{ sm: '16px' }} color={grayscale[400]}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, ducimus voluptatum. Laboriosam est eius voluptatibus temporibus, sed vel ipsa id sint debitis molestiae a, officiis commodi repudiandae modi minus eos.
          </InterP>
          <InterP fontSize={{ sm: '13px' }} lineHeight={{ sm: '16px' }} color={grayscale[400]}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, ducimus voluptatum. Laboriosam est eius voluptatibus temporibus, sed vel ipsa id sint debitis molestiae a, officiis commodi repudiandae modi minus eos.
          </InterP>
          <InterP fontSize={{ sm: '13px' }} lineHeight={{ sm: '16px' }} color={grayscale[400]}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis, ducimus voluptatum. Laboriosam est eius voluptatibus temporibus, sed vel ipsa id sint debitis molestiae a, officiis commodi repudiandae modi minus eos.
          </InterP>
        </S.CardBody>
      </S.ClientQuoteCards>
    </SectionComponent>
  )
}

export default OQueClientesDizem
