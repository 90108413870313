import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

type ButtonWithDeepLinkProps = {
  onClick: () => void;
  onLinkClick: () => void;
  children: React.ReactNode;
  href: string;
  className?: string;
}

export const ButtonWithDeepLink = ({ onClick, onLinkClick, children, href, className }: ButtonWithDeepLinkProps) => {
  const width = useWidth()
  return width < WIDTH_MD ? (
    <a
      target='blank'
      href={href}
      className={className}
      onClick={onLinkClick}
    >
      {children}
    </a>
    ) : (
      <button
        className={className}
        onClick={onClick}
      >
        {children}
      </button>
  )
}
