import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import SectionComponent from 'src/components/SectionComponent'
import { primary } from 'src/styles/newColors'
import { H2Citrina } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'
import { ButtonWithDeepLink } from 'src/pages/recarga/components/_ButtonWithDeepLink'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { WIDTH_LG, WIDTH_MD, WIDTH_SM, WIDTH_XL } from 'src/styles/breakpoints'
import { SeguroPixInterProps } from '../_types'
import * as S from './_style'

const TEXT_CONTENT = {
  heading: 'O Pix do inter é simples, rápido e seguro',
  paragraph: 'Aqui você faz suas transações de forma intuitiva e com poucos cliques.',
  cta: 'Fazer Pix',
}

const dataLayerEvent: IDataLayerParams = {
  section: 'dobra_07',
  element_action: 'click button',
  element_name: TEXT_CONTENT.cta,
  section_name: TEXT_CONTENT.heading,
}

const PixSeguro = ({ oneLink, onCtaClickHandler }: SeguroPixInterProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleBtnClick = () => {
    onCtaClickHandler(PixSeguro.name)
    sendDatalayerEvent(dataLayerEvent)
  }

  const getVideoHeight = () => {
    if (width <= WIDTH_SM) return 419
    if (width <= WIDTH_MD) return 310
    if (width <= WIDTH_LG) return 413
    if (width <= WIDTH_XL) return 413
    return 582
  }

  return (
    <SectionComponent
      id='pix-seguro'
      SectionStyles='pb-0'
      minHeight={{ sm: '500px' }}
      bgColor={primary[100]}
    >
      <div className='col-12 col-md-6'>
        <H2Citrina
          fontWeight={{ sm: '700', md: '700', lg: '700', xl: '700' }}
          className='mb-4'
          fontSize={{ xl: '40px' }}
          lineHeight={{ xl: '44px' }}
        >{TEXT_CONTENT.heading}
        </H2Citrina>
        <InterP className='mb-4'>
          {TEXT_CONTENT.paragraph}
        </InterP>
        <ButtonWithDeepLink
          className='mt-3 btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none text-md-center'
          href={oneLink}
          onClick={handleBtnClick}
          onLinkClick={() => sendDatalayerEvent(dataLayerEvent)}
        >
          {TEXT_CONTENT.cta}
        </ButtonWithDeepLink>
      </div>
      <S.VideoContainer className='col-12 col-md-6'>
        <video
          key='introVideo'
          height={getVideoHeight()}
          width='auto'
          loop
          muted
          autoPlay
          className='py-sm-5 py-md-0 d-block mx-auto'
        >
          <source
            id='videoWebm'
            type='video/webm'
            src='https://central-imagens.bancointer.com.br/gif/gif-pix-app/arquivo.webm'
          />
        </video>
      </S.VideoContainer>
    </SectionComponent>
  )
}

export default PixSeguro
