import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'

import useDomReady from 'src/hooks/window/useDomReady'
import { Wrapper } from './style'
import pageContext from './pageContext.json'
import ModalWindow from './modal-window/_index'

import {
  HeroPix,
  FAQComponent,
  MelhorPix,
  QuaisChavesPix,
  PixCredito,
  PixSeguro,
  ComoCadastrar,
  ContrateSeguroPix,
  BlogPix,
} from './sections/_index'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import QrSeguroPix from './assets/images/qr_code_lp_pix_seguropix.png'
import { LpPixModalFactoryProps } from './sections/_types'

const LpPixModalFactory = ({ qrCodeId, isModalOpen, setIsModalOpen }: LpPixModalFactoryProps) => {
  const domReady = useDomReady()
  if (!isModalOpen) return null

  if (qrCodeId === 'qrcodeSeguroPix') {
    return domReady && (
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
        <QrCodeModal
          isModal={isModalOpen}
          setIsModal={setIsModalOpen}
          title='Escaneie o QR Code, acesse o app'
          instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o Super App do Inter. Aí é só contratar o Seguro Pix e aproveitar sua conta digital.'
          qrCode={QrSeguroPix}
        />
      </Modal>

    )
  }
  return domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <ModalWindow closeModal={() => setIsModalOpen(false)} qrCodeId={qrCodeId} />
    </Modal>
  )
}

const Pix = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ qrCodeId, setQrCodeId ] = useState('qrcodePixLP')

  const oneLinkLP = 'https://intergo.app/e3c4c81f'

  const handleModalState = (sectionName: string) => {
    const MODAL_MAPPING = {
      [HeroPix.name]: 'qrcodePixLP',
      [QuaisChavesPix.name]: 'qrcodePixLP',
      [ComoCadastrar.name]: 'qrcodePixLP',
      [PixCredito.name]: 'qrcodePixLP',
      [PixSeguro.name]: 'qrcodePixLP',
      [ContrateSeguroPix.name]: 'qrcodeSeguroPix',
    }
    setQrCodeId(MODAL_MAPPING[sectionName])
    setIsModalOpen((prev: boolean) => !prev)
}

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <LpPixModalFactory qrCodeId={qrCodeId} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <HeroPix onCtaClickHandler={handleModalState} oneLink={oneLinkLP} />
        <MelhorPix onCtaClickHandler={handleModalState} oneLink={oneLinkLP} />
        {/* <OQueClientesDizem /> */}
        <QuaisChavesPix />
        <ComoCadastrar onCtaClickHandler={handleModalState} oneLink={oneLinkLP} />
        <PixCredito onCtaClickHandler={handleModalState} oneLink={oneLinkLP} />
        <PixSeguro onCtaClickHandler={handleModalState} oneLink={oneLinkLP} />
        <ContrateSeguroPix onPlanBtnClickHandler={handleModalState} oneLink={oneLinkLP} />
        <BlogPix />
        <FAQComponent faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default Pix
