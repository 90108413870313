import React from 'react'
import Stepper from './components/Stepper/_index'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_SM } from 'src/styles/breakpoints'
import { InterP } from 'src/components/Text/BodyText'
import { ButtonWithDeepLink } from 'src/pages/recarga/components/_ButtonWithDeepLink'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import SectionComponent from 'src/components/SectionComponent'
import { primary } from 'src/styles/newColors'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { ComoCadastrarProps } from '../_types'
import { H2Citrina } from 'src/components/Text/Headings'

const INSTRUCTIONS_STEPS = [
  {
    title: 'Acesse a área Pix',
  },
  {
    title: 'Clique em “Cadastrar chave”',
  },
  {
    title: 'Escolha o tipo de chave',
  },
  {
    title: 'Confirme o token',
  },
  {
    title: 'Pronto!',
  },
]

const TEXT_CONTENT = {
  heading: 'Como cadastrar sua chave Pix?',
  paragraph: 'Agora é só aproveitar os benefícios do melhor Pix!',
  cta: 'Cadastrar chave e fazer pix',
}

const dataLayerEvent: IDataLayerParams = {
  section: 'dobra_04',
  element_action: 'click button',
  element_name: TEXT_CONTENT.cta,
  section_name: TEXT_CONTENT.heading,
}

const ComoCadastrar = ({ oneLink, onCtaClickHandler }: ComoCadastrarProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const onBtnClick = () => {
    onCtaClickHandler(ComoCadastrar.name)
    sendDatalayerEvent(dataLayerEvent)
  }
  return (
    <SectionComponent
      minHeight={{ sm: '517px', md: '432px', lg: '527px', xl: '811px', xxl: '811px' }}
      id='como-cadastrar'
      bgColor={width <= WIDTH_SM ? primary[100] : 'transparent'}
    >
      <div className='col-12 col-md-6'>
        <H2Citrina
          fontWeight={{ sm: '700', md: '700', lg: '700', xl: '700' }}
          fontSize={{ md: '23px', xl: '28px' }}
          lineHeight={{ md: '13px', xl: '33px' }}
        >
          {TEXT_CONTENT.heading}
        </H2Citrina>
        <div className='my-4 py-2 py-md-0'>
          <Stepper data={INSTRUCTIONS_STEPS} />
        </div>
        <InterP fontSize={{ md: '13px', lg: '14px' }} lineHeight={{ md: '13px', lg: '17px' }} className='mb-3'>
          {TEXT_CONTENT.paragraph}
        </InterP>
        <ButtonWithDeepLink
          className='mt-3 btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none text-md-center'
          href={oneLink}
          onClick={onBtnClick}
          onLinkClick={() => sendDatalayerEvent(dataLayerEvent)}
        >
          {TEXT_CONTENT.cta}
        </ButtonWithDeepLink>

      </div>
      <div className='col-md-6 d-none d-md-block'>
        <ImageWebpWithSizes
          className='d-block float-md-right float-lg-none'
          pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pix-dobra4/image.webp'
          altDescription='mulher com roupas laranja, encostada em bicicleta e mexendo no celular'
          widths={{ md: 300, lg: 376 }}
        />
      </div>
    </SectionComponent>
  )
}

export default ComoCadastrar
