import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

import * as S from './_style'
import { ModalWindowProps, useModalLpPixDataT } from '../sections/_types'
import { useModalLpPixData } from './hooks/_useModalLPPixData'

const ModalWindow = ({ closeModal, qrCodeId = 'qrcodePixLP' }: ModalWindowProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { getImageFluid, getLink }: useModalLpPixDataT = useModalLpPixData()

  const closeButton = closeModal && (
    <S.CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </S.CloseButton>
  )

  return (
    <S.Container isModal={closeModal} className='d-flex align-items-start align-items-md-center mx-auto'>
      {closeButton}
      <div className='row p-md-5'>
        <div className='col-12 py-4 pt-lg-0'>
          <h3 className='fs-32 lh-40 fw-600 text-grayscale--500 mb-4'>Acesse o Pix no Super App!</h3>
          <p className='d-none d-md-block fs-16 lh-20 fw-400 text-grayscale--400 mb-0'>Para acessar, é só entrar no Super App Inter e tocar em Pix no menu principal. Você também pode acessar o app diretamente apontando a câmera do seu celular para fazer a leitura do código QR:</p>
          <p className='d-md-none fs-18 lh-22 fw-400 text-grayscale--400 mb-4'>Para acessar, é só entrar no Super App Inter e tocar em Pix no menu principal. Você também pode acessar o app diretamente através do botão abaixo:</p>
          <S.QRCodeDiv className='d-none d-md-flex justify-content-center'>
            <Img fluid={getImageFluid(qrCodeId)} alt='QR code para o app do Inter.' />
          </S.QRCodeDiv>
          <div>
            <a href={getLink(qrCodeId)} className='d-md-none btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none text-md-center'>Acessar Super App</a>
            <button
              onClick={() => {
                  closeModal()
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: 'Entendi',
                    section_name: 'Acesse o Pix no Super App!',
                  })
              }}
              className='d-none d-md-block btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none text-md-center'
            >
              Entendi
            </button>
          </div>
        </div>
      </div>
    </S.Container>
  )
}

export default ModalWindow
