import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import SectionComponent from 'src/components/SectionComponent'
import { H2Citrina } from 'src/components/Text/Headings'
import { ButtonWithDeepLink } from 'src/pages/recarga/components/_ButtonWithDeepLink'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { grayscale, orange } from 'src/styles/colors'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import { InterP } from 'src/components/Text/BodyText'
import { WIDTH_SM } from 'src/styles/breakpoints'
import * as S from './_style'
import { ContrateSeguroContentT, ContrateSeguroPixProps, PlanT } from '../_types'

const TEXT_CONTENT: ContrateSeguroContentT = {
  heading: 'Seguro Pix do Inter',
  plans: [
    {
      planName: 'Plano Standard',
      planPrice: 'Valor total: R$3,50/mês',
      planDescription: 'Cobertura de R$2.000,00 em caso de transações sob coação ou violência e transações realizadas com o celular roubado ou furtado.',
    },
    {
      planName: 'Plano Plus',
      planPrice: 'Valor total: R$8,00/mês',
      planDescription: 'Cobertura de R$5.000,00 em caso de transações sob coação ou violência e transações realizadas com o celular roubado ou furtado.',
    },
  ],
  cta: 'Contratar',
}

const dataLayerEvent: IDataLayerParams = {
  section: 'dobra_08',
  element_action: 'click button',
  element_name: TEXT_CONTENT.cta,
  section_name: TEXT_CONTENT.heading,
}

const ContrateSeguroPix = ({ oneLink, onPlanBtnClickHandler }: ContrateSeguroPixProps) => {
  const width = useWidth()

  const bgUrl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pix-dobra7/image.webp'

  return (
    <SectionComponent
      id='contrate-pix-seguro'
      minHeight={{ sm: '571px', md: '446px', lg: '595px', xl: '838px', xxl: '838px' }}
      bgColor={width <= WIDTH_SM ? orange.extra : ''}
      bgTablet={bgUrl}
      bgDesktopLG={bgUrl}
      bgDesktopXL={bgUrl}
      bgDesktopXXL={bgUrl}
      backgroundSize='cover'
    >
      <div className='col-12 col-md-7 col-lg-6'>
        {width <= WIDTH_SM
          ? (<ContrateSeguroPix.SMView onPlanBtnClickHandler={onPlanBtnClickHandler} oneLink={oneLink} />)
          : (<ContrateSeguroPix.DefaultView onPlanBtnClickHandler={onPlanBtnClickHandler} oneLink={oneLink} />)}
      </div>
    </SectionComponent>
  )
}

ContrateSeguroPix.SMView = ({ oneLink, onPlanBtnClickHandler }: ContrateSeguroPixProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const handleBtnClick = (planName: string) => {
    onPlanBtnClickHandler(ContrateSeguroPix.name)
    sendDatalayerEvent({
      ...dataLayerEvent,
      section_name: `${TEXT_CONTENT.heading} - ${planName}`,
    })
  }
  return (
    <>
      <H2Citrina
        fontWeight={{ sm: '700', md: '700', lg: '700', xl: '700' }}
        color='#fff'
        className='mb-4'
      >{TEXT_CONTENT.heading}
      </H2Citrina>
      {TEXT_CONTENT.plans.map((plan: PlanT, index: number) => (
        <>
          <S.PlanBlock key={'dobra_08' + index} className='mb-4'>
            <div>
              <OrangeIcon size='MD' icon='no-taxes' color='#fff' />
            </div>
            <div>
              <strong className='text-white d-block mb-2'>{plan.planName}</strong>
              <InterP className='text-white mb-2'>{plan.planPrice}</InterP>
              <InterP className='text-white'>{plan.planDescription}</InterP>
            </div>
          </S.PlanBlock>
          <ButtonWithDeepLink
            className='mb-4 text-orange--extra btn bg-white btn--lg rounded-2 fs-14 fw-700 text-none text-center'
            href={oneLink}
            onClick={() => handleBtnClick(plan.planName)}
            onLinkClick={() => sendDatalayerEvent({
              ...dataLayerEvent,
              section_name: `${TEXT_CONTENT.heading} - ${plan.planName}`,
            })}
          >
            {TEXT_CONTENT.cta}
          </ButtonWithDeepLink>
        </>
    ))}
    </>
  )
}

ContrateSeguroPix.DefaultView = ({ oneLink, onPlanBtnClickHandler }: ContrateSeguroPixProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const handleBtnClick = (planName: string) => {
    onPlanBtnClickHandler(ContrateSeguroPix.name)
    sendDatalayerEvent({
      ...dataLayerEvent,
      section_name: `${TEXT_CONTENT.heading} - ${planName}`,
    })
  }
  return (
    <S.PlanBlockBox>
      <H2Citrina
        fontWeight={{ sm: '700', md: '700', lg: '700', xl: '700' }}
        className='mb-4'
        fontSize={{ xl: '40px' }}
        lineHeight={{ xl: '44px' }}
      >{TEXT_CONTENT.heading}
      </H2Citrina>
      {TEXT_CONTENT.plans.map((plan: PlanT, index: number) => (
        <div className='mb-2' key={'dobra_08' + index}>
          <S.PlanBlock>
            <S.PlanTitle>
              <OrangeIcon size='SM' icon='i-safe' color={grayscale[500]} />
              <strong className='d-blocktext-grayscale--500 fs-lg-14 fs-xl-18 lh-xl-21'>{plan.planName}</strong>
            </S.PlanTitle>
            <S.PlanDescription>
              <InterP color={grayscale[500]} className='mb-2 fs-lg-14 fs-xl-18 lh-xl-21'>{plan.planPrice}</InterP>
              <InterP color={grayscale[500]} className='fs-lg-14 fs-xl-18 lh-xl-21'>{plan.planDescription}</InterP>
            </S.PlanDescription>
          </S.PlanBlock>
          <S.PlanBlockBtn
            className='mt-2'
            href={oneLink}
            onClick={() => handleBtnClick(plan.planName)}
            onLinkClick={() => sendDatalayerEvent({
              ...dataLayerEvent,
              section_name: `${TEXT_CONTENT.heading} - ${plan.planName}`,
            })}
          >
            <span className='mr-2'>{TEXT_CONTENT.cta}</span><OrangeIcon size='SM' icon='arrow-right' color={orange.extra} />
          </S.PlanBlockBtn>
        </div>
    ))}
    </S.PlanBlockBox>
  )
}

export default ContrateSeguroPix
