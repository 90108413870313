import styled from 'styled-components'
import { Card } from '@interco/inter-ui/components/Card'
import { breakpoints } from 'src/styles/breakpoints'

export const CardWithHeight = styled(Card)`
  min-height: 152px;
  @media (min-width:${breakpoints.lg}){
    min-width: 216px;
  }
`
export const CardFlexbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
  @media (min-width:${breakpoints.md}){
    justify-content: center;
  }

  @media (min-width:${breakpoints.lg}){
    max-width: 712px;
    margin: 0 auto;
  }

  @media (min-width:${breakpoints.xl}){
    max-width: 1200px;
    margin: 24px auto;
  }

  ${CardWithHeight} {
    flex-grow: 1;
    @media (min-width:${breakpoints.md}) {
      max-width: 216px;
    }
    @media (min-width:${breakpoints.xl}) {
      max-width: 334px;
    }
  }
`
