
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const CardWrapper = styled.a`
  cursor: pointer;
  display: block;
  margin-bottom: 24px;
  border-radius: 22px;
  overflow: hidden;
  @media (min-width: ${breakpoints.md}) {
    min-height: 315px;
  }
`

export const CardBody = styled.div`
  position: relative;
  z-index: 2;
  background-color: #fff;
  top: -25px;
  border-radius: 22px;
  padding: 24px;
  @media (min-width: ${breakpoints.md}) {
    min-height: 377px;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 281px;
  }
`

export const CategoryLabel = styled.span`
  background-color: ${orange.extra};
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  color: #fff;
  width: auto;
`

export const SubCategoryLabel = styled.span`
  background-color: ${grayscale[100]};
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  font-size: 12px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 8px;
  }
`

export const CategoriesHeader = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  margin-bottom: 14px;
`

export const ImageWrapper = styled.div`
  position: relative;
  width: auto;
  height: 100%;
  display: block;
  min-height: 244px;
  background-color: ${orange.extra};
  img {
    position: absolute;
    object-fit: cover;
  }
`

export const TitleWrapper = styled.div``

export const TextWrapper = styled(TitleWrapper)`
  flex-grow: 1;
`
