
import React from 'react'

import { BlogCardT } from './_types'
import Icon from 'src/components/UI/MarkdownIcon'
import * as S from './_style'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'

type BlogCardProps = {
  card: BlogCardT;
  onCardClick: () => void;
  showImage?: boolean;
};

const BlogCard = ({ card, onCardClick, showImage = true }: BlogCardProps) => {
  return (
    <S.CardWrapper
      href={card.link}
      target='_blank'
      rel='noreferrer'
      onClick={onCardClick}
      aria-label={card.title}
    >
      {showImage && (
        <S.ImageWrapper>
          <ImageWebpWithSizes pathSrc={card.image} heights={{ sm: 244 }} altDescription='' aria-hidden='true' />
        </S.ImageWrapper>
        )}
      <S.CardBody>
        <S.CategoriesHeader>
          <S.CategoryLabel>
            {card.category}
          </S.CategoryLabel>
        </S.CategoriesHeader>
        <S.TitleWrapper>
          <h3 className='font-citrina fs-20 lh-24 fs-md-16 lh-20 fw-500 text-grayscale--500 mb-2' dangerouslySetInnerHTML={{ __html: card.title }} />
          {card.date && <span className='fs-12 lh-15 fw-700 text-grayscale--300'>{card.date}</span>}
        </S.TitleWrapper>
        <S.TextWrapper>
          <p className='fs-16 lh-20 fs-md-13 lh-17 text-grayscale--400 mb-2' dangerouslySetInnerHTML={{ __html: card.description }} />
        </S.TextWrapper>
        <div className='mt-2'>
          <BlogCard.ReadMoreBtn />
        </div>
      </S.CardBody>
    </S.CardWrapper>
  )
}

BlogCard.ReadMoreBtn = () => (
  <span
    title='Leia mais'
    className='fs-14 lh-17 fw-400 text-orange--extra text-right d-block w-100'
  >
    Leia mais
    <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
  </span>
)

export default BlogCard
