import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import articlesListData from './data.json'
import { BlogCardT } from 'src/components/BlogCard/_types'
import SectionComponent from 'src/components/SectionComponent'
import { primary } from 'src/styles/newColors'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import BlogCard from './BlogCard/_BlogCard'
import { H2Citrina } from 'src/components/Text/Headings'

const TEXT_CONTENT = {
  heading: 'Confira mais conteúdos sobre Pix',
}

const dataLayerEvent: IDataLayerParams = {
  section: 'dobra_09',
  section_name: TEXT_CONTENT.heading,
  element_action: 'click button',
  element_name: '',
  redirect_url: '',
}

const BlogPix = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const articlesList: BlogCardT[] = articlesListData
  return (
    <SectionComponent
      bgColor={primary[100]}
      id='blog_pix'
      minHeight={{ sm: '1630px', md: '690px', lg: '753px', xl: '763px', xxl: '763px' }}
    >
      <div className='col-12 text-md-center'>
        <H2Citrina
          fontWeight={{ sm: '700', md: '700', lg: '700', xl: '700' }}
          fontSize={{ xl: '40px' }}
          lineHeight={{ xl: '44px' }}
          className='mb-4'
        >{TEXT_CONTENT.heading}
        </H2Citrina>
      </div>
      <div className='col-12'>
        <div className='row'>
          {articlesList.map((card: BlogCardT) => (
            <div key={card.id} className='col-md-4'>
              <BlogCard
                card={card}
                onCardClick={
                () => sendDatalayerEvent({
                  ...dataLayerEvent,
                  element_name: card.title,
                  redirect_url: card.link,
                })}
              />
            </div>
            ))}
        </div>
      </div>
    </SectionComponent>
  )
}

export default BlogPix
