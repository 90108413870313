import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'

import { H2Citrina } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'
import SectionComponent from 'src/components/SectionComponent'
import { WIDTH_MD, WIDTH_SM } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import * as S from './_style'

const CARDS_CONTENT = [
  {
    icon: 'user-account',
    title: 'CPF ou CNPJ',
  },
  {
    icon: 'smartphones',
    title: 'Celular',
  },
  {
    icon: 'email',
    title: 'Email',
  },
  {
    icon: 'asterisk',
    title: 'Chave aleatória',
  },
]

const TEXT_CONTENT = {
  heading: 'É muito fácil cadastrar sua chave Pix!',
  paragraph: 'Você pode cadastrar esses tipos de chave:',
}

const QuaisChavesPix = () => {
  const width = useWidth()
  return (
    <SectionComponent
      id='quais-chaves-pix'
      minHeight={{ sm: '186px', md: '186px', lg: '248px', xl: '350px', xxl: '350px' }}
      bgColor={orange.extra}
      SectionStyles='py-md-4'
    >
      <div className='col-12 col-md-5 col-lg-5'>
        <H2Citrina
          fontWeight={{ sm: '700', md: '700', lg: '700', xl: '700' }}
          className='mb-3'
          color='#fff'
        >
          {TEXT_CONTENT.heading}
        </H2Citrina>
        {width <= WIDTH_SM && (
          <InterP className='my-sm-3' color='#fff'>
            {TEXT_CONTENT.paragraph}
          </InterP>
        )}
      </div>
      <div className='col-12 col-md-7 col-lg-6 col-xl-6'>
        {width >= WIDTH_MD && (
          <p className='font-citrina fs-md-18 fw-600 lh-md-17 text-white m-0'>
            {TEXT_CONTENT.paragraph}
          </p>
        )}
        <S.CardGrid>
          {CARDS_CONTENT.map((item: {icon: string; title: string }, key: number) => (
            <S.CardWithHeight className='d-flex align-items-center' key={key}>
              <div className='mr-2'>
                <OrangeIcon icon={item.icon} size='MD' color={orange.extra} />
              </div>
              <p className='mb-0 fs-13 lh-16 text-grayscale--500 fw-600 ml-2'>{item.title}</p>
            </S.CardWithHeight>
        ))}
        </S.CardGrid>
      </div>
    </SectionComponent>
  )
}

export default QuaisChavesPix
