import { ButtonWithDeepLink } from 'src/pages/recarga/components/_ButtonWithDeepLink'
import { breakpoints } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import { primary } from 'src/styles/newColors'
import styled from 'styled-components'

export const PlanTitle = styled.div``
export const PlanDescription = styled.div``

const blockMargin = '20px'

export const PlanBlock = styled.div`
  margin-bottom: 20px;

  ${PlanTitle} {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    strong {
      margin-left: 4px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }

  ${PlanDescription} { 
    margin-left: ${blockMargin};
  }
`

export const PlanBlockBtn = styled(ButtonWithDeepLink)`
  color: ${orange.extra};
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  justify-content: start;
  background: transparent;
`

export const PlanBlockBox = styled.div`
  padding: 24px;
  background-color: ${primary[100]};
  border-radius: 20px;

  @media (min-width: ${breakpoints.md}) {
    ${PlanBlockBtn} {
      margin-left: ${blockMargin};
    }
  }
`
