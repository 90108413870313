import React from 'react'

export const wrappTextOnStrongTag = (text: string, selectedText: string) => {
  const index = text.indexOf(selectedText)

  if (index === -1) {
    throw new Error('Selected text not found in the string')
  }
  const firstPart = text.slice(0, index)
  const lastPart = text.slice(index + selectedText.length)
  return (
    <>{firstPart}<strong>{selectedText}</strong>{lastPart}</>
  )
}
