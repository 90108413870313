import styled from 'styled-components'
import { Card } from '@interco/inter-ui/components/Card'
import { breakpoints } from 'src/styles/breakpoints'

export const CardWithHeight = styled(Card)`
  min-height: fit-content;
  padding: 8px;
`

export const CardGrid = styled.div`
  margin-top: 24px;
  &>div:not(:last-child) {
    margin-bottom: 16px;
  }
  @media (min-width: ${breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-top: 12px;
    &>div:not(:last-child) {
      margin: 0;
    }
  }
`
