import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      #used
      qrcodePixEInter: imageSharp(fluid: { originalName: { regex: "/qrcode-lppix-pix-e-inter/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      #used
      qrcodePixLP: imageSharp(fluid: { originalName: { regex: "/qr_code_lp_pix/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      #used
      qrcodeSeguroPix: imageSharp(fluid: { originalName: { regex: "/qr_code_lp_pix_seguropix/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      heroBannerImagePix: imageSharp(fluid: { originalName: { regex: "/hero-banner-image-pix/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerOQueEPixMobile: imageSharp(fluid: { originalName: { regex: "/banner-o-que-e-pix-mobile/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerComoFuncionaOPix: imageSharp(fluid: { originalName: { regex: "/banner-como-funciona-o-pix/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerOPixTemLimiteMobile: imageSharp(fluid: { originalName: { regex: "/banner-o-pix-tem-limite-mobile/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerPixSaqueETroco: imageSharp(fluid: { originalName: { regex: "/banner-pix-saque-e-troco/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerComoBaixarOAppParaFazerPix: imageSharp(fluid: { originalName: { regex: "/banner-como-baixar-o-app-para-fazer-pix/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerComoPagarComPix: imageSharp(fluid: { originalName: { regex: "/banner-como-pagar-com-pix/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerComoReceberPeloPix: imageSharp(fluid: { originalName: { regex: "/banner-como-receber-pelo-pix/" }}) {
        fluid(maxWidth: 457, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      qrcodeComoBaixarOApp: imageSharp(fluid: { originalName: { regex: "/qrcode-lppix-como-baixar-o-app/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      qrcodeComoFunciona: imageSharp(fluid: { originalName: { regex: "/qrcode-lppix-como-funciona/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      qrcodeOQueEPix: imageSharp(fluid: { originalName: { regex: "/qrcode-lppix-o-que-e-pix/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      qrcodePagarPix: imageSharp(fluid: { originalName: { regex: "/qrcode-lppix-pagar-pix/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      
      
      qrcodePixESeguro: imageSharp(fluid: { originalName: { regex: "/qrcode-lppix-pix-e-seguro/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      qrcodePixSaqueETroco: imageSharp(fluid: { originalName: { regex: "/qrcode-lppix-pix-saque-e-troco/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      qrcodePixTemLimite: imageSharp(fluid: { originalName: { regex: "/qrcode-lppix-pix-tem-limite/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      qrcodeReceberPix: imageSharp(fluid: { originalName: { regex: "/qrcode-lppix-receber-pix/" }}) {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      quaisChavesImage: imageSharp(fluid: { originalName: { regex: "/quais-chaves-image/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      pixEmProcessamento: imageSharp(fluid: { originalName: { regex: "/pix-em-processamento/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      pixSaqueEPixTroco: imageSharp(fluid: { originalName: { regex: "/pix-saque-e-pix-troco/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      saibaComoGerarPix: imageSharp(fluid: { originalName: { regex: "/saiba-como-gerar-pix/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      planoStandard: imageSharp(fluid: { originalName: { regex: "/img-plano-standard/" } }) {
        fluid(maxWidth: 64, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      planoPlus: imageSharp(fluid: { originalName: { regex: "/img-plano-plus/" } }) {
        fluid(maxWidth: 64, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
